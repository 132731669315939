import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataServicesService } from '../backend-services/data-services.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router:Router,private dataserv:DataServicesService) { }
  // public socialMediaList: any[] = [];
  contactInfo: any;
  ngOnInit(): void {
    // this.dataserv.getSocialMedia().subscribe(res=>{
    //     this.socialMediaList=res;
    // })
    this.dataserv.getContactUsInfo().subscribe(res => {
      this.contactInfo = res;
    })
  }
  public navigateTo(){
    this.router.navigate(['/home'], { fragment: 'services' });
  }

    

}
