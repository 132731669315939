<div class="preload-wrapper" *ngIf="show">
  <img src="../assets/LogoLoading.png" alt="Bus Rent" name="busRent" />
  <div></div>
</div>

<div *ngIf="!show">
  <nav
    class="navbar navbar-expand-lg"
    [ngClass]="home === true ? 'bg-darkblue' : 'bg-notdark'"
    *ngIf="notFound"
  >
    <div class="container-fluid">
      <a class="navbar-brand ms-5" href="#"
        ><img class="img-logo" src="../assets/logo.png" alt="Bus Ren"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              routerLink="/forside"
              routerLinkActive="active"
            >
              <!-- <span class="span">
                <div class="first"><i class="bi bi-star-fill fs-10"></i></div>
                <div class="second">
                  <i class="bi bi-star-fill fs-8"></i>
                  <i class="bi bi-star-fill fs-7"></i>
                </div>
              </span> -->
              Forside</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="service"
              routerLinkActive="active"
              style="cursor: pointer"
            >
              <!-- <span class="span">
                <div class="first"><i class="bi bi-star-fill fs-10"></i></div>
                <div class="second">
                  <i class="bi bi-star-fill fs-8"></i>
                  <i class="bi bi-star-fill fs-7"></i>
                </div>
              </span> -->
              Ydelser</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/digital-cleaning"
              routerLinkActive="active"
            >
              <!-- <span class="span">
                <div class="first"><i class="bi bi-star-fill fs-10"></i></div>
                <div class="second">
                  <i class="bi bi-star-fill fs-8"></i>
                  <i class="bi bi-star-fill fs-7"></i>
                </div>
              </span> -->
              Digital rengøring
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/about-us"
              routerLinkActive="active"
            >
              <!-- <span class="span">
                <div class="first"><i class="bi bi-star-fill fs-10"></i></div>
                <div class="second">
                  <i class="bi bi-star-fill fs-8"></i>
                  <i class="bi bi-star-fill fs-7"></i>
                </div>
              </span> -->
              Om os</a
            >
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/clients" routerLinkActive="active">
              <!-- <span class="span">
                <div class="first"><i class="bi bi-star-fill fs-10"></i></div>
                <div class="second">
                  <i class="bi bi-star-fill fs-8"></i>
                  <i class="bi bi-star-fill fs-7"></i>
                </div>
              </span> -->
              Kunder</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/contact-us"
              routerLinkActive="active"
            >
              <!-- <span class="span">
                <div class="first"><i class="bi bi-star-fill fs-10"></i></div>
                <div class="second">
                  <i class="bi bi-star-fill fs-8"></i>
                  <i class="bi bi-star-fill fs-7"></i>
                </div>
              </span> -->
              Kontakt os</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <router-outlet> </router-outlet>

  <app-footer *ngIf="notFound"></app-footer>
  <button class="arrow-to-up" (click)="UpPage()" *ngIf="scrolledDown">
    <i class="bi bi-arrow-up-circle-fill"></i>
  </button>
</div>
