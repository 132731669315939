import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { contactModel } from '../sharedServices/contactModel';
import { langCode } from '../sharedServices/constants/languages';
@Injectable({
  providedIn: 'root'
})
export class DataServicesService {
  constructor(private http: HttpClient) { }

  public postContactForm(form:contactModel):Observable<any>{
    return this.http.post<any>(`${environment.backend_API}/ContactUs/AddContactUs`,form);
  }
  public postDigitalForm(form:contactModel):Observable<any>{
    return this.http.post<any>(`${environment.backend_API}/Quotation`,form);
  }
  public getSliders():Observable<any>{
    return this.http.get<any>(`
    ${environment.backend_API}/Slider/GetAllSliderByLanguage?page=1&size=5&langCode=${langCode}`)
  }
  public getServices():Observable<any>{
    return this.http.get<any>(`
    ${environment.backend_API}/Services?langCode=${langCode}`)
  }
  public getAboutUs():Observable<any>{
    return this.http.get<any>(`
    ${environment.backend_API}/AboutUs?langCode=${langCode}`)
  }
  public postQuotation(quoatation:contactModel):Observable<any>{
    return this.http.post<contactModel>(`${environment.backend_API}/Quotation`,quoatation)
  }
  public getCareers():Observable<any>{
    return this.http.get<any>(
      `${environment.backend_API}/Career?langCode=en-US`
    )
  }
  public getWhyUs():Observable<any>{
    return this.http.get<any>(`${environment.backend_API}/WhyUs?langCode=en-US`)
  }
  public getContactUsInfo():Observable<any>{
    return this.http.get<any>(`${environment.backend_API}/ContactUsInfo?langCode=en-US`)
  }

  public getSocialMedia():Observable<any>{
    return this.http.get<any>(`${environment.backend_API}/SocialMedia`)
  }
  public getSectionUnderSlider():Observable<any>{
    return this.http.get<any>(`${environment.backend_API}/SectionUnderSlider/GetSectionUnderSliderFront?langCode=en-US`)
  }

  public getHomeContent():Observable<any>{
    return this.http.get<any>(`${environment.backend_API}/Home?langCode=${langCode}`)
  }
  public getDigitalCleaning():Observable<any>{
    return this.http.get<any>(`${environment.backend_API}/DigitalCleaning/GetDigitalCleaningFront?langCode=${langCode}`)
  }

  public getDigitalCleaningImage():Observable<any>{
    return this.http.get<any>(`${environment.backend_API}/ContactUs/GetContactImage`)
  }

  public getServiceContent():Observable<any>{
    return this.http.get<any>(`${environment.backend_API}/Services/GetServicesFrontDetails?langCode=${langCode}`)
  }
  public getClientsHome():Observable<any>{
    return this.http.get<any>(`${environment.backend_API}/Client/GetClientFront?langCode=${langCode}`)
  }
}
