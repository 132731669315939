import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataServicesService } from './backend-services/data-services.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Bus-Ren';
  show = true;
  home = true;
  service = false;
  notFound: boolean = true;
  submission: boolean = false;
  scrolledDown:boolean=false;
  currentPosition = window.pageYOffset;

  constructor(private router: Router) {

  }
 
  ngOnInit(): void {

    setTimeout(() => {
      this.show = false;
    }, 3000);
    this.checkPath();
   

  }

  public navigateTo() {
    this.service = true;
    this.router.navigate(['/home'], { fragment: 'services' });
  }

  public checkPath(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.includes('home') && event.url.includes('services') === false) {
            this.home = true;
            this.notFound = true;
            this.service = false;
          }
          else if (event.url.includes('not-found')) {
            this.notFound = false;
            this.service = false;
          }
          else if (event.url.includes('services')) {
            this.service = true;
            this.home = true;
          }
          else {
            this.home = false;
            this.notFound = true;
            this.service = false;
          }

        }
        // let url:string = event.url;

      });

  }

    public UpPage(){
      window.scrollTo({top:0,behavior: 'smooth'});
     
    }
    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event:any) {
      ($event.target.documentElement.scrollTop > 100) ? this.scrolledDown = true : this.scrolledDown = false;  
    }
}
