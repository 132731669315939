<footer>
  <div class="foot">
    <h5>Bus Ren</h5>
    <ul>
      <li>
        <a routerLink="/service">Ydelser</a>
      </li>
      <li>
        <a routerLink="/digital-cleaning">Digital rengøring</a>
      </li>
      <li>
        <a routerLink="/clients">Kunder</a>
      </li>
      <li>
        <a routerLink="/about-us">Om os</a>
      </li>
    </ul>
  </div>
  <div class="foot">
    <h5>Kontakt</h5>
    <ul *ngIf="contactInfo">
      <li>
        <a
          style="color: #fff !important"
          href="tel:+45963836"
          [innerHTML]="contactInfo['contactUsInfoTranslations'].contactUsInfo"
        >
          Telefon:</a
        >
      </li>
    </ul>
  </div>
  <div class="foot">
    <h5>Adresse</h5>
    <ul *ngIf="contactInfo">
      <li
        style="line-height: 30px; margin-bottom: 0"
        [innerHTML]="contactInfo['contactUsInfoTranslations'].addressInfo"
      >
        Hvidegårdsparken 5, <br />
        2800 Kongens Lyngby
      </li>
    </ul>
  </div>
  <!-- <div class="social-media-icons">
    <ul>
      <li *ngFor="let socialmedia of socialMediaList">
        <a
          [href]="'https://' + socialmedia?.link"
          [class]="socialmedia?.icon"
        ></a>
      </li>
    </ul>
  </div> -->
</footer>
